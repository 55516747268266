import * as React from "react"
import Layout from "../components/layout"
import cloud from "../assets/Memory storage-rafiki 1.png"
import { Helmet } from "react-helmet"

export default function System() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>aiKATE | System Integration</title>
        <link rel="canonical" href="https://aikate.eu/system" />
        <meta
          name="System integration"
          content="Software development, IoT solutions, System integration"
        />
      </Helmet>
      <div className="px-4 py-24 mx-auto sm:max-w-xl md:max-w-full pt-12 lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col  lg:flex-row">
          <div className="mb-6 lg:mb-0 lg:w-1/2 pt-12 lg:pr-5">
            <h2 className="mb-5 font-Manrope text-5xl font-bold tracking-tight text-white sm:text-4xl lg:text-7xl sm:leading-none">
              System Integration
            </h2>
            <p className="text-3xl md:pt-4 lg:w-2/2 text-white">
              Network design serves as the foundation for effective network
              monitoring, and we are here to deliver the best solution for your
              business no matter the size. Based on your company's individual
              needs we design and implement a solution whether an entirely new
              networking system or something else.
            </p>
          </div>

          <div className="pl-4  mb-4">
            <img src={cloud} alt="cloud"></img>
          </div>
        </div>
      </div>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:pb-40 lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid gap-8 row-gap-5 md:grid-cols-2">
          <div className="card2 relative p-px overflow-hidden transition duration-300 transform  rounded-xl  hover:scale-105 group hover:shadow-xl">
            <div className="absolute bottom-0 left-0 w-full h-1 duration-300 origin-left transform scale-x-0  group-hover:scale-x-100" />
            <div className="absolute bottom-0 left-0 w-1 h-full duration-300 origin-bottom transform scale-y-0  group-hover:scale-y-100" />
            <div className="absolute top-0 left-0 w-full h-1 duration-300 origin-right transform scale-x-0 group-hover:scale-x-100" />
            <div className="absolute bottom-0 right-0 w-1 h-full duration-300 origin-top transform scale-y-0  group-hover:scale-y-100" />
            <div className="relative flex flex-col h-full p-5  rounded-sm lg:items-center lg:flex-row">
              <div className="mb-6 mr-6 lg:mb-0">
                <div className="flex items-center justify-center w-20 h-20 rounded-full bg-blue-100 lg:w-32 lg:h-32">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10"
                    viewBox="0 0 20 20"
                    fill="#005BEA"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2 5a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm14 1a1 1 0 11-2 0 1 1 0 012 0zM2 13a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2zm14 1a1 1 0 11-2 0 1 1 0 012 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </div>
              <div className="flex flex-col justify-between flex-grow">
                <div>
                  <h6 className="mb-2 text-2xl text-white font-bold leading-5">
                    Network design
                  </h6>
                  <p className="mb-2 text-lg text-white">
                    The way your network is designed impacts your network
                    monitoring capabilities and your system performance. In
                    today's fast-paced world identifying problematic equipment
                    and errors on time is crucial and with good network design,
                    you will always be one step ahead.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card2 relative p-px overflow-hidden transition duration-300 transform  rounded-xl  hover:scale-105 group hover:shadow-xl">
            <div className="absolute bottom-0 left-0 w-full h-1 duration-300 origin-left transform scale-x-0  group-hover:scale-x-100" />
            <div className="absolute bottom-0 left-0 w-1 h-full duration-300 origin-bottom transform scale-y-0  group-hover:scale-y-100" />
            <div className="absolute top-0 left-0 w-full h-1 duration-300 origin-right transform scale-x-0  group-hover:scale-x-100" />
            <div className="absolute bottom-0 right-0 w-1 h-full duration-300 origin-top transform scale-y-0  group-hover:scale-y-100" />
            <div className="relative flex flex-col h-full p-5 rounded-sm lg:items-center lg:flex-row">
              <div className="mb-6 mr-6 lg:mb-0">
                <div className="flex items-center justify-center w-20 h-20 rounded-full bg-blue-100 lg:w-32 lg:h-32">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10"
                    viewBox="0 0 20 20"
                    fill="#005BEA"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </div>
              <div className="flex flex-col justify-between flex-grow">
                <div>
                  <h6 className="mb-2 text-white text-2xl font-bold leading-5">
                    Network monitoring
                  </h6>
                  <p className="mb-2 text-lg text-white">
                    Your company infrastructure needs to be monitored to prevent
                    possible attacks and respond promptly. We are specialized in
                    Nagios design and implementation which helps find the
                    permanent solution to your problem.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-24 pt-20 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="max-w-lg mb-6 font-sans text-5xl font-bold leading-none tracking-tight text-white lg:text-5xl sm:text-4xl md:mx-auto">
            Phases
          </h2>
          <p className="text-xl text-white md:text-xl">
            Our team understands and designs in a way that your company's needs
            are put first. After analyzing we will make suggestions to fully
            suit your business needs and wants.
          </p>
        </div>
        <div className="relative grid gap-8 row-gap-5 mb-8 md:row-gap-8 lg:grid-cols-4 sm:grid-cols-2">
          <div className="absolute inset-0 flex items-center justify-center sm:hidden lg:flex">
            <div className="w-px h-full bg-gray-300 lg:w-full lg:h-px" />
          </div>
          <div className="card3 p-5 duration-300 transform  shadow-sm hover:-translate-y-2">
            <div className="flex items-center justify-between mb-2">
              <p className="text-lg text-white font-bold leading-5">
                Information & specifications gathering
              </p>
              <p className="flex items-center justify-center w-6 h-6 font-bold rounded text-gray-900 bg-blue-300">
                1
              </p>
            </div>
            <p className="text-sm text-gray-900"></p>
          </div>
          <div className="card3 p-5 duration-300 transform shadow-sm hover:-translate-y-2">
            <div className="flex items-center justify-between mb-2">
              <p className="text-lg text-white  font-bold leading-5">
                Data analyis
              </p>
              <p className="flex items-center justify-center w-6 h-6 font-bold rounded text-gray-900 bg-blue-300">
                2
              </p>
            </div>
            <p className="text-sm text-gray-900"></p>
          </div>
          <div className="card3 p-5 duration-300 transform shadow-sm hover:-translate-y-2">
            <div className="flex items-center justify-between mb-2">
              <p className="text-lg text-white font-bold leading-5">
                Design & implementation
              </p>
              <p className="flex items-center justify-center w-6 h-6 font-bold rounded text-gray-900 bg-blue-300">
                3
              </p>
            </div>
            <p className="text-sm text-gray-900"></p>
          </div>
          <div className="card3 p-5 duration-300 transform  shadow-sm hover:-translate-y-2">
            <div className="flex items-center justify-between mb-2">
              <p className="text-lg text-white font-bold leading-5">
                Evaluation & maintenance
              </p>
              <p className="flex items-center justify-center w-6 h-6 font-bold rounded text-gray-900 bg-blue-300">
                4
              </p>
            </div>
            <p className="text-sm text-gray-900"></p>
          </div>
        </div>
      </div>
      <br></br>

      <div className="card px-3 py-12 mx-auto sm:max-w-xl rounded rounded-xl  lg:max-w-screen-xl md:px-8 lg:px-4 lg:py-10">
        <div className="max-w-xl sm:mx-auto lg:max-w-xl">
          <div className="flex flex-col mb-10 sm:text-center sm:mb-0">
            <div className="mb-6 sm:mx-auto"></div>
            <div className="max-w-xl mb-10 md:mx-auto sm:text-center sm:max-w-md lg:max-w-xl md:mb-12">
              <h2 className="max-w-lg mb-6 font-sans text-6xl font-bold sm:pl-6 leading-none tracking-tight text-white sm:text-4xl lg:text-5xl md:mx-auto">
                Need more info?
              </h2>
              <p className="text-base text-white sm:text-center sm:pl-6 md:text-lg">
                Drop us a message!
              </p>
            </div>
            <div>
              <a
                href="mailto:info@aikate.hr"
                className="contactbutton2 inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md hover:text-opacity-50 focus:shadow-outline focus:outline-none"
              >
                Contact us
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
